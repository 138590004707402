/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    strong: "strong",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {TableOfContents, MapInfo, LandingPageCta, ThreeColCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!MapInfo) _missingMdxReference("MapInfo", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  if (!ThreeColCta) _missingMdxReference("ThreeColCta", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "controlling-hearing-aids-with-your-smartphone",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#controlling-hearing-aids-with-your-smartphone",
    "aria-label": "controlling hearing aids with your smartphone permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Controlling Hearing Aids with Your Smartphone"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Innovative features allow people to combine the ", React.createElement(_components.a, {
    href: "/hearing-aids/technology",
    className: "c-md-a"
  }, "technology of their hearing aid"), " and phones. Newer devices come with wireless interfaces that connect to Android or iPhone iOS."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Technological advances make it simple to adjust the settings on your ", React.createElement(_components.a, {
    href: "/hearing-aids",
    className: "c-md-a"
  }, "hearing aids"), " without touching them. You can also use ", React.createElement(_components.a, {
    href: "/hearing-aids/technology/bluetooth",
    className: "c-md-a"
  }, "Bluetooth wireless technology"), " to listen to music, stream your favorite TV shows, and make phone calls from your hearing aids."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "benefits-of-combining-hearing-aids-and-smartphones",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#benefits-of-combining-hearing-aids-and-smartphones",
    "aria-label": "benefits of combining hearing aids and smartphones permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Benefits of Combining Hearing Aids and Smartphones"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing aids that include Bluetooth technology can connect to any compatible smartphone. You can download a smartphone application that comes with the device to set up and manage every available setting, such as sound and volume."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "You can enjoy a range of benefits depending on the ", React.createElement(_components.a, {
    href: "/hearing-aids/types",
    className: "c-md-a"
  }, "type of hearing aid"), " that meets your specific needs. The most common benefits are below."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Interactive Listening")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing aids detect and evaluate the wearer’s surroundings. The technology built into the device allows it to adapt rapidly to the environment for optimal hearing. Instead of taking the hearing aid out of your ear to change the settings, you can manage all the features with the touch of a button on your phone."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "You can also control and manage different applications. Drown out unnecessary background noise so you can communicate with others at a loud restaurant. The voices you hear will be clearer and easy to understand despite the surrounding sound."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Locate Lost Hearing Aids")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "You might misplace your hearing aids during periods when you don’t wear them. When that happens, you could waste precious time trying to find them. Fortunately, modern hearing aids now contain GPS tracking systems. You can use your smartphone to track the location of your device quickly so you can put them in your ears and get on with your day."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The app will provide the exact location on a map in real time. This feature also tracks your location, so you know whether you’re moving further or closer to your hearing aids. You no longer have to worry about spending your time looking for your lost device when you’re in a rush to get out the door."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Voice Clarity")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Use the microphone feature to hear others better in noisy environments. They can wear a tiny microphone on their clothing so you can hear them at a loud bar, crowded event, or noisy park. The microphone wirelessly transmits the voice of the speaker to the hearing aid. You won’t miss out on important conversations anymore or misunderstand what people say to you."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Manage Fewer Devices")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "An additional device called a streamer transmits signals to a hearing aid from a mobile phone. People with hearing loss can wear it around their necks to use all the convenient features their hearing aids offer."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "However, it requires carrying around an additional device. Instead, you can connect your hearing aids directly to your smartphone. You don’t have to carry multiple gadgets with you all day. Additionally, you will have fewer devices to maintain."), "\n", React.createElement(MapInfo), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "what-features-are-available-through-your-smartphone",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-features-are-available-through-your-smartphone",
    "aria-label": "what features are available through your smartphone permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What Features Are Available Through Your Smartphone?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "A remote control hearing aid offers various features to make your life easier, such as:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Automatic adjustments"), " – You can adjust your hearing aids with your phone depending on the environment. However, some hearing aids have customizable programs that automatically adjust when they detect certain noise levels."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Personalized programs"), " – You can create and store different programs on your phone for locations you frequently visit. For example, if you stop at a coffee shop on your way to work every morning, use your phone to set a comfortable hearing level. You can choose the programmed setting whenever you're in the coffee shop."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Device status"), " – You can check the status of your hearing aids on your phone. Look up the battery life so you know when to replace or charge the batteries. You could avoid a situation where you can’t hear others because you have to charge your hearing aids."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Review the manual"), " – Instead of lugging around a bulky manual, look up what you need to know on your phone. You can open the app and determine how to set up your new hearing aids, adjust settings, or change the batteries while on the go."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Audio connection"), " – You can connect your hearing aids to audio using Bluetooth. Stream music, TV shows, podcasts, and more directly to your hearing aids. You can use your phone to control what you listen to and adjust the volume. You can also route your phone calls to your hearing aids, so you don't have to pick up your phone during conversations. This provides better sound quality and removes unwanted background noise."), "\n"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "hearing-aids-recommendations-for-smartphone-connectivity",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hearing-aids-recommendations-for-smartphone-connectivity",
    "aria-label": "hearing aids recommendations for smartphone connectivity permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Hearing Aids Recommendations for Smartphone Connectivity"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/",
    className: "c-md-a"
  }, "Hear.com"), " provides our customers with high-quality, ", React.createElement(_components.a, {
    href: "/hearing-aids/horizon",
    className: "c-md-a"
  }, "top-performing hearing aids from Horizon IX"), ", including ", React.createElement(_components.a, {
    href: "/hearing-aids/horizon/go-ix",
    className: "c-md-a"
  }, "Horizon Go IX"), " and ", React.createElement(_components.a, {
    href: "/hearing-aids/horizon/mini-ix",
    className: "c-md-a"
  }, "Horizon Mini IX"), ". Both come with a downloadable ", React.createElement(_components.a, {
    href: "/hearing-aids/horizon/app/",
    className: "c-md-a"
  }, "Horizon app"), ", so all features are right at your fingertips."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Our Horizon IX hearing aids have a sleek and nearly invisible design. They also include cutting-edge technology, such as:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Bluetooth connectivity"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Speech Focus"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Portable charging cases"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/technology/own-voice-processing",
    className: "c-md-a"
  }, "Own voice processing")), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Directional microphones"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Acoustic-motion sensors"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Tinnitus therapy"), "\n"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "how-hearcom-can-help",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-hearcom-can-help",
    "aria-label": "how hearcom can help permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How hear.com Can Help"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "At hear.com, we only work with the top 2% of hearing professionals in the country. Our hearing aid smartphone technology can improve your hearing, relationships, and quality of life. We will focus on your unique needs and offer a device suited to your level of hearing impairment."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "We believe everyone deserves a chance to enjoy better hearing in any environment. Our team can evaluate your hearing with a ", React.createElement(_components.a, {
    href: "/hearing-test/",
    className: "c-md-a"
  }, "hearing test"), ", recommend a device that fits your budget and lifestyle, and give you time to wear your new hearing aids to determine if you like them."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "contact-an-experienced-audiologist-today",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#contact-an-experienced-audiologist-today",
    "aria-label": "contact an experienced audiologist today permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Contact an Experienced Audiologist Today"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hear.com understands the convenience of connecting hearing aids and phones. ", React.createElement(_components.a, {
    href: "https://www.hear.com/d/full_page_questionnaire",
    className: "c-md-a"
  }, "Take our questionnaire"), " to determine your eligibility for a no-risk hearing aid trial."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Don’t worry about being stuck with a device that doesn’t work for you. Our trial periods allow you to get used to the look and feel of your hearing aids so you can decide if you want to keep them or try a different model."), "\n", React.createElement(LandingPageCta, {
    copy: "Start No-risk Trial",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "resources",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#resources",
    "aria-label": "resources permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Resources"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Learn everything you need to know about hearing aids and hearing loss."), "\n", React.createElement(ThreeColCta, {
    ImgSrc1: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/women-with-tablet.jpg",
    ContentTitle1: "Hearing Test",
    ContentCopy1: "Check how is your hearing in less than 5 minutes.",
    Url1: "/hearing-test/",
    ImgSrc2: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-aids-behind-ear-blur.jpg",
    ContentTitle2: "Value & Price",
    ContentCopy2: "Are hearing aids worth the cost? We asked an expert.",
    Url2: "/hearing-aids/prices/",
    ImgSrc3: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/go-ax-grey-blur.jpg",
    ContentTitle3: "Hearing Aids",
    ContentCopy3: "Connect you to the world around you with our wide range of devices.",
    Url3: "/hearing-aids/"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
